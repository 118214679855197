import { useState } from "react";
import React, { useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import emailjs from '@emailjs/browser';

export const Contact = () => {

    const form = useRef();

    const formInitialDetails = {
        firstName: '',
        email: '',
        message: ''
    }

    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Send');
    const [status, setStatus] = useState({});
    const [timer, setTimer] = useState(null); 

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }

    const handleSubmit = async (e) => {
        
        e.preventDefault();

        if (!formDetails.firstName && !formDetails.email.trim() && !formDetails.message) {
            setStatus({ success: false, message: "All fields are required" });
            return;
        }

        if (!formDetails.firstName) {
            setStatus({ success: false, message: "Field First Name is required" });
            return;
        }

        if (!formDetails.email) {
            setStatus({ success: false, message: "Field email is required" });
            return;
        }

        if (!formDetails.message) {
            setStatus({ success: false, message: "Fields message is required" });
            return;
        }

        setButtonText('Sending...');
        setFormDetails(formInitialDetails);
        emailjs
        .sendForm('service_crkqtlr', 'template_rnf4nlv', form.current, {
            publicKey: 'NToB6EeamnLl_VQlK',
        })
        .then(
            () => {
                setButtonText("Send");
                setStatus({success: true, message: "Message send Succesfully"});
            },
            (error) => {
                console.log('FAILED...', error.text);
                setStatus({success: false, message: "Something went wrong, please try again later"});
            },
        );

        if (timer) clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setStatus({});
        }, 5000);
        setTimer(newTimer); 

    };

    return (
        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    
                    <Col md={12}>
                        <h2>Get In Touch</h2>
                        <form ref={form} onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={6} className="px-1">
                                    <input type="text" name="user_name" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input type="email" name="user_email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                                </Col>
                                <Col sm={12} className="px-1">
                                    <textarea row="6" name="message" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                                    <button type="submit"><span>{buttonText}</span></button>
                                </Col>
                                {
                                    status.message &&
                                    <Col className="text-center req-st" style={{marginTop: '20px', margin: '23px auto', padding: '17px'}}>
                                        <p style={{ padding: '0'}} className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                    </Col>
                                }
                            </Row>
                        </form>
                    </Col>
                    
                </Row>
            </Container>
        </section>
    )

}